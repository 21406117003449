.loading-page {
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.text {
  text-align: center;
}

.logo {
  display: block;
  width: 100px;
  margin: 0 auto;
  border-radius: 20px;
}

.dots {
  animation: fadeOut 1s ease alternate;
  animation-delay: 2.5s;
}

.dot {
  opacity: 0;
}

.dot:nth-child(1) {
  animation: dot-one 2s infinite linear;
}

.dot:nth-child(2) {
  animation: dot-two 2s infinite linear;
}

.dot:nth-child(3) {
  animation: dot-three 2s infinite linear;
}

@keyframes dot-one {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dot-two {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dot-three {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
