/* cyrillic-ext */
@font-face {
  font-family: '__Mulish_be37ff';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Mulish_be37ff';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Mulish_be37ff';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Mulish_be37ff';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Mulish_be37ff';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Mulish_Fallback_be37ff';src: local("Arial");ascent-override: 96.56%;descent-override: 24.02%;line-gap-override: 0.00%;size-adjust: 104.08%
}.__className_be37ff {font-family: '__Mulish_be37ff', '__Mulish_Fallback_be37ff';font-style: normal
}.__variable_be37ff {--mulish-font: '__Mulish_be37ff', '__Mulish_Fallback_be37ff'
}

.loading-page_loading-page__oFqCn{position:fixed;z-index:1000;display:flex;align-items:center;justify-content:center;width:100%;height:100vh;background-color:#fff}.loading-page_text__mwiOn{text-align:center}.loading-page_logo__b_Zdh{display:block;width:100px;margin:0 auto;border-radius:20px}.loading-page_dots__VB3Nc{animation:loading-page_fadeOut__RoFw1 1s ease alternate;animation-delay:2.5s}.loading-page_dot__6sdXP{opacity:0}.loading-page_dot__6sdXP:nth-child(1){animation:loading-page_dot-one__MY2LF 2s infinite linear}.loading-page_dot__6sdXP:nth-child(2){animation:loading-page_dot-two__FgQsw 2s infinite linear}.loading-page_dot__6sdXP:nth-child(3){animation:loading-page_dot-three__raMCf 2s infinite linear}@keyframes loading-page_dot-one__MY2LF{0%{opacity:0}15%{opacity:0}25%{opacity:1}100%{opacity:1}}@keyframes loading-page_dot-two__FgQsw{0%{opacity:0}25%{opacity:0}50%{opacity:1}100%{opacity:1}}@keyframes loading-page_dot-three__raMCf{0%{opacity:0}50%{opacity:0}75%{opacity:1}100%{opacity:1}}
